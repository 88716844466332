<template id="responsive-map">
	<two-cards>
		<template #header>
			<h4 class="card-title">Filtros</h4>
		</template>
		<template #one>
			<form @submit.prevent="search()">
				<div class="form-row">
					<div class="form-group col-sm-4">
						<label for="i_nome">Área *</label>
						<vue-multi-select
							class="multi-100"
							searchPlaceholder="Pesquisar"
							v-model="areaSelecionada"
							:options="{ multi: false, labelName: 'nome' }"
							:selectOptions="areasOpcoes"
							:filters="multiSelectFilters[0]"
							@input="filtraMaquinas"
							:btnLabel="() => (areaSelecionada[0] && areaSelecionada[0].nome) || 'Selecione'"
						/>
					</div>
					<div class="form-group col-sm-4">
						<label for="i_nome">Máquinas *</label>
						<vue-multi-select
							class="multi-100"
							:disabled="!areaSelecionada.length"
							search
							searchPlaceholder="Pesquisar"
							v-model="maquinaSelecionada"
							:options="{ multi: false, labelName: 'nome' }"
							:selectOptions="maquinasOpcoes"
							:filters="multiSelectFilters[0]"
							:btnLabel="() => (maquinaSelecionada[0] && maquinaSelecionada[0].nome) || 'Selecione'"
						/>
					</div>
					<div class="form-group col-sm-4">
						<label for="i_nome">Tipo de exibição *</label>
						<vue-multi-select
							class="multi-100"
							v-model="tiposExibicaoSelecionado"
							:options="{ multi: false, labelName: 'nome' }"
							:selectOptions="tiposExibicao"
							:filters="multiSelectFilters[0]"
							:btnLabel="() => (tiposExibicaoSelecionado[0] && tiposExibicaoSelecionado[0].nome) || 'Selecione'"
						/>
					</div>
				</div>

				<div class="form-row">
					<div class="form-group col-sm-4">
						<label for="data-inicial">Data inicial *</label>
							<datepicker
								input="date"
								placeholder="Inicio"
								id="data-inicial"
								input-class="bg-white"
								v-model="filtros.inicio"
								@selected="filtroData(filtros)"
								:format="formatter"
								:language="ptBR"
								:bootstrap-styling="true"
								:state="validaData"
								data-cy="Data Inicial"
							></datepicker>
					</div>
					<div class="form-group col-sm-4">
						<label for="data-final">Data final *</label>
							<datepicker
								placeholder="Fim"
								id="data-final"
								input-class="bg-white"
								v-model="filtros.fim"
								@selected="filtroData(filtros)"
								:format="formatter"
								:language="ptBR"
								:bootstrap-styling="true"
								data-cy="Data Final"
							></datepicker>
					</div>
					<div class="form-group col-sm-4">
						<label>Distância entre pontos (metros)</label>
						<vue-slider
							v-model="sliderValue"
							:min="1"
							:max="10000"
							:marks="marks"
							:contained="true"
						/>
					</div>
				</div>

				<b-alert show variant="info" class="text-left my-auto" v-if="Math.abs(calculaDatas) <= 15">
					O período de pesquisa deverá ser no máximo 15 dias. Período atual: {{ Math.abs(calculaDatas) }} dia(s).
				</b-alert>
				<b-alert show variant="danger" class="text-left my-auto" v-else>
					O período de pesquisa deverá ser no máximo 15 dias. Período atual: {{ Math.abs(calculaDatas) }} dia(s).
				</b-alert>

				<p class="small text-right mt-2 mb-0">
					Campos marcados com * são obrigatórios.
				</p>

				<div class="d-flex justify-content-end tcs-card-footer-action">
					<button
						class="btn btn-primary mr-3"
						type="button"
						@click="exportaHistoricoDePosicoes()"
						:disabled="!maquinaSelecionada[0] || dataLoading || Math.abs(calculaDatas) > 15 || flagExportando"
					>
						<span
							v-if="flagExportando"
							class="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"></span>
						<DownloadIcon v-if="!flagExportando" />
						Exportar
					</button>
					<button
						@click ="search()"
						class="btn btn-success tcs-btn"
						type="submit"
						data-cy="Pesquisar"
						:disabled="!maquinaSelecionada[0] || dataLoading || Math.abs(calculaDatas) > 15"
					>
						<SearchIcon />
					</button>
				</div>
			</form>
		</template>
		<template #two>
			<tbody v-if="dataLoading">
				<tb-skeleton shape="rect" theme="opacity"></tb-skeleton>
			</tbody>
			<template v-else>
				<b-alert show variant="info" class="text-left mb-3">
					Os pontos de localização que estiverem a até aproximadamente {{distanciaSlider}}
					um do outro são unidos em um só ponto no mapa, mas se encontram separados no relatório de exportação.
				</b-alert>
				<gmap-map v-if="ready"
					ref="mapRef"
					:center="center"
					:zoom="zoom"
					:options= "{gestureHandling}"
					map-type-id="roadmap"
					style="width: 100%; height: 500px;"
				>
					<gmap-marker
						:key="index"
						v-for="(m, index) in markersMapa"
						:position="m.position"
						:icon="m.icon ? m.icon : ''"
						:clickable="true"
						@dblclick="zoomIn(m)"
						@click="selectedMarker = m"
					/>
				</gmap-map>
				<p class="text-center my-auto" v-if="!pesquisaRealizada">
					Selecione as datas de início e fim da pesquisa.
				</p>
				<b-alert show variant="warning" class="text-center my-auto" v-if="markers.length == 0 && pesquisaRealizada">
					Nenhum resultado encontrado para o período pesquisado.
				</b-alert>
				<localization-summary
					v-if="selectedMarker != null && ready"
					:marker="selectedMarker"
					@close="selectedMarker = null"
				/>
			</template>
		</template>
	</two-cards>
</template>

<script>
	import { ptBR } from "vuejs-datepicker/dist/locale";
	import Datepicker from "vuejs-datepicker";
	import "vue2-google-maps";
	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";
	import VueSlider from "vue-slider-component";
	import "vue-slider-component/theme/default.css";

	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";
	import { saveAs } from "file-saver";
	import { getCenter, getDistance } from "geolib";

	import TwoCards from "@/templates/TwoCards.vue";
	import LocalizationSummary from "./LocalizationSummary.vue";

	import { AreasService } from "../../services/areas";
	import { LocalizationService } from "@/services/localization";
	import { MaquinasService } from "../../services/maquinas";
	import { RelatoriosService } from "../../services/relatorios";
	import { PreferencesService } from "@/services/preferencias";

	export default {
		components: {
			TwoCards,
			VueMultiSelect,
			Datepicker,
			LocalizationSummary,
			VueSlider
		},

		data () {
			return {
				sliderValue: 10000,
				flagExportando:false,
				initialDate: null,
				preferenceService: new PreferencesService(),
				relatoriosService: new RelatoriosService(),
				center: { lat: -20, lng: -44 },
				zoom: 7,
				gestureHandling: "greedy",
				markers: [],
				markersMapa: [],
				positions: [],
				ptBR,

				areasOpcoes: [],
				areaSelecionada: [],
				maquinas:[],
				maquinaSelecionada: [],
				maquinasOpcoes: [],
				preferences: [],
				selectedMarker: null,
				dataLoading: false,
				ready: false,
				pesquisaRealizada: false,

				multiSelectFilters: [
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters()
				],

				filtros: {
					inicio: dayjs().format("YYYY-MM-DD"),
					fim: dayjs().format("YYYY-MM-DD")
				},

				localizationService: new LocalizationService(),
				areasService: new AreasService(),
				maquinasService: new MaquinasService(),
				tiposExibicao: [
					{ id_tipoExib: 1, nome: "Marcadores" },
					{ id_tipoExib: 2, nome: "Arestas" },
					{ id_tipoExib: 3, nome: "Arestas (com distância entre vértices)" },
					{ id_tipoExib: 4, nome: "Marcadores e Arestas" }
				],
				tiposExibicaoSelecionado: [],
				exibicaoTipo: 1,
				marks: [1, 2500, 5000, 7500, 10000]
			};
		},

		computed: {
			validaData () {
				return dayjs(dayjs(this.filtros.inicio).format("YYYY-MM-DD")).isBefore(dayjs(this.filtros.fim).format("YYYY-MM-DD"));
			},

			calculaDatas () {
				const dataInicio = dayjs(this.filtros.inicio).format("YYYY-MM-DD");
				const dataFinal = dayjs(this.filtros.fim).format("YYYY-MM-DD");

				return dayjs(dataFinal).diff(dataInicio, "days") + 1;
			},

			distanciaSlider () {
				if (this.sliderValue == 1) return `${this.sliderValue} metro`;
				else return `${this.sliderValue} metros`;
			}
		},

		async mounted () {
			this.maquinas = (await this.maquinasService.listMachines() || []).map(m => ({
				nome: m.nome,
				...m
			}));
			this.areasOpcoes = (await this.areasService.listAreas() || []).map(m => ({
				nome: m.nome,
				...m
			}));
			this.preferences = await this.preferenceService.getPreferences(["AREA_MAPAS"]);
			const idArea = Number((this.preferences.find(p => p.nome === "AREA_MAPAS") || {}).valor);
			this.areaSelecionada[0] = this.areasOpcoes.find(p => p.id === idArea) || [];
			this.tiposExibicaoSelecionado = this.tiposExibicao.slice(-1);
			this.exibicaoTipo = 1;

			this.filtraMaquinas();
			this.filtroData(this.filtros);
		},

		methods: {
			filtraMaquinas () {
				this.maquinasSelecionadas = [];
				if (this.areaSelecionada[0])
					this.maquinasOpcoes = this.maquinas.filter(m => m.id_area == this.areaSelecionada[0].id);
			},
			zoomIn (m) {
				this.center.lat = m.position.lat + 0.01;
				this.center.lng = m.position.lng + 0.01;
				this.zoom = 17;

				// Altera os valores duas vezes para garantir que o Vue vai
				// repassa-los para o mapa, mesmo que já fossem iguais,
				// pois o usuário pode ter movido.
				setImmediate(() => {
					this.center.lat = m.position.lat;
					this.center.lng = m.position.lng;
					this.zoom = 19;
				});
			},

			customLabel (selected) {
				return selected.length
					? `${selected.length} selecionada${
						selected.length > 1 ? "s" : ""
					}`
					: "Selecione";
			},

			getMultiSelectFilters (feminino) {
				return [{
					nameAll: `Selecionar tod${feminino ? "a" : "o"}s`,
					nameNotAll: `Desselecionar tod${feminino ? "a" : "o"}s`,
					func () {
						return true;
					}
				}];
			},

			async search () {
				const dataInicial = dayjs(this.filtros.inicio).format("YYYY-MM-DD");
				const dataFinal = dayjs(this.filtros.fim).format("YYYY-MM-DD");
				const dataInvalida = dayjs(dataFinal).isBefore(dataInicial) || dayjs(dataFinal).isAfter(dayjs().format("YYYY-MM-DD"));
				if (dataInvalida) {
					this.$swal({
						type: "warning",
						title: "Selecione uma data válida!",
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
					return;
				}

				try {
					this.dataLoading = true;
					this.ready = false;
					this.pesquisaRealizada = false;

					const maquina = this.maquinaSelecionada[0];
					const identificadorLocalizacao = (maquina).identificador_localizacao;
					this.exibicaoTipo = this.tiposExibicaoSelecionado[0].id_tipoExib;

					this.markers = await this.localizationService.getHistoricoLocalizacao(
						identificadorLocalizacao, dataInicial, dataFinal, maquina, this.sliderValue
					) || [];

					// Atribuição da bandeira ao ultimo marcador
					if (this.markers.length) {
						this.markers[this.markers.length - 1].icon = {
							url: "/icons/map-markers/flag.png",
							scaledSize: {
								width: 48,
								height: 69
							}
						};
					}

					if ((this.exibicaoTipo == 2 || this.exibicaoTipo == 3) && this.markers.length > 1) {
						this.markersMapa = [];
					} else {
						this.markersMapa = this.markers;
					}

					this.selectedMarker = null;
					if (!identificadorLocalizacao) {
						this.dataLoading = false;
						this.ready = false;

						this.$swal({
							type: "warning",
							title: "A máquina não possui identificador de localização!",
							confirmButtonText: "Fechar",
							confirmButtonColor: "#6c757d"
						});
						return;
					}

					if (this.markers.length) {
						this.dataLoading = false;
						this.ready = true;

						await this.autoZoom(this.markers).catch((error) => {
							console.error("Não foi possível renderizar o auto zoom!", error);
						});
					}

					this.pesquisaRealizada = true;
					this.dataLoading = false;

					const element = document.getElementById("cardMapa");
					const topEl = element.offsetTop - 59;
					window.scrollTo({ top: topEl, behavior: "smooth" });
				} catch (err) {
					console.error(err);
					this.dataLoading = false;
					this.ready = false;

					this.$swal({
						type: "error",
						title: "Falha ao fazer a busca!",
						html: `<p>${err.response ? err.response.error : err.message}</p>`,
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
				}
			},

			async autoZoom (markers) {
				if (!markers) return;

				this.$nextTick(() => {
					if (this.$refs.mapRef) {
						this.$refs.mapRef.$mapPromise.then(() => {
							const map = this.$refs.mapRef.$mapObject;
							const latLngBounds = new google.maps.LatLngBounds();
							const markersPositions = [];

							for (const marker of markers) {
								latLngBounds.extend(marker.position);
								markersPositions.push(marker.position);
							}

							if (markersPositions.length >= 2 && map && (
								this.exibicaoTipo == 2 || this.exibicaoTipo == 3 || this.exibicaoTipo == 4
							)) {
								this.distanceBetweenMarkers(markersPositions, map);
							}

							map.fitBounds(latLngBounds);
						}).catch((error) => {
							console.error("Erro ao renderizar mapa!", error);
						});
					}
				});
			},

			distanceBetweenMarkers (markersPositions, map) {
				let p1 = [];
				let p2 = [];
				let between = [];
				let center = {};
				const lineSymbol = {
					path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
				};
				const polyline = new google.maps.Polyline({
					path: markersPositions,
					geodesic: true,
					strokeColor: "#FF0000",
					strokeOpacity: 0.9,
					strokeWeight: 2,
					icons: [{
						icon: lineSymbol,
						offset: "100%",
						repeat: "50px"
					}],
					map
				});

				const tamanhoMarkers = markersPositions.length;

				for (let i = 0; i < tamanhoMarkers - 1; i++) {
					p1 = markersPositions[i];
					p2 = markersPositions[i + 1];

					between = getCenter([p1, p2]);
					center = {
						lat: between.latitude,
						lng: between.longitude
					};

					let label = "";
					if (this.exibicaoTipo != 2) {
						const distance = getDistance(p1, p2) / 1000;
						label = {
							text: `${distance.toFixed(2) + " km"}`,
							color: "#000000",
							className: "bg-light",
							fontWeight: "bold"
						};
					}

					new google.maps.Marker({
						label,
						position: center,
						icon: {
							url: "",
							position: center,
							labelOrigin: new google.maps.Point(55, 50),
							scaledSize: { width: 50, height: 50 }
						},
						zIndex: 100,
						map,
						visible: true
					});
				}

				polyline.setMap(map);
			},

			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			},

			filtroData (fr) {
				if (fr.inicio)
					this.filtros.inicio = dayjs(fr.inicio).toDate();

				if (fr.fim)
					this.filtros.fim = dayjs(fr.fim).toDate();
			},

			async exportaHistoricoDePosicoes () {
				const dataInicial = dayjs(this.filtros.inicio).format("YYYY-MM-DD");
				const dataFinal = dayjs(this.filtros.fim).format("YYYY-MM-DD");
				const dataInvalida = dayjs(dataFinal).isBefore(dataInicial) || dayjs(dataFinal).isAfter(dayjs().format("YYYY-MM-DD"));

				if (dataInvalida) {
					this.$swal({
						type: "warning",
						title: "Selecione uma data válida!",
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
					return;
				}

				if (this.maquinaSelecionada[0]) {
					const filtrosPlanilha = {
						filtro:{
							imei: this.maquinaSelecionada[0].identificador_localizacao,
							dataInicial: dayjs(this.filtros.inicio).format("YYYY-MM-DD"),
							dataFinal: dayjs(this.filtros.fim).format("YYYY-MM-DD")
						},
						area: this.maquinaSelecionada[0].area.nome,
						maquina: this.maquinaSelecionada[0].nome
					};

					this.flagExportando = true;
					const relatorio = await this.relatoriosService.geraPlanilha(filtrosPlanilha);
					if (relatorio && !relatorio.message) {
						const blob = new Blob([relatorio.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
						saveAs(blob, "Relatorio-de-posicionamento" + ".xlsx");
						this.flagExportando = false;
					} else {
						this.flagExportando = false;
						this.$swal({
							title: relatorio.message,
							type: relatorio.type || "warning",
							confirmButtonText: "Fechar",
							confirmButtonColor: "#6c757d"
						});
					}
				} else {
					this.$swal({
						title: "Selecione uma máquina!",
						type: "warning",
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
				}
			}
		}
	};
</script>

<style>
	@media (max-width: 790px) {
		main .card:first-child {
			border: 0;
			min-height: auto !important;
		}
	}

	.invalid-select {
		width: 100%;
		margin-top: 0.25rem;
		font-size: 80%;
		color: #dc3545;
		display: block;
	}

	.btn-primary {
		color: #fff;
		background-color: #6DCFF6;
		border-color: #6DCFF6;
		border-radius: 10px 10px 0px 0;
	}

	.btn-primary:hover {
		color: #fff;
		background-color: #59b0d3;
		border-color: #59b0d3;
	}

	.btn-primary:disabled {
		color: #fff;
		background-color: #59b0d3;
		border-color: #59b0d3;
	}

	.form-control {
		height: auto !important;
	}

	.tb-skeleton {
		background-color: #dcdcdc;
		height: 25rem;
		width: 100%;
	}

	.vue-slider-mark:nth-child(2) .vue-slider-mark-label {
		padding-right: 17px;
	}
</style>
