import Axios from "axios";
import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class RelatoriosService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async geraPlanilha (payload) {
		try {
			const resposta = await this.axios.post(
				"relatorio/relatorioPosicao",
				payload,
				{ responseType: "arraybuffer" }
			);
			return resposta;
		} catch (error) {
			let erro = Buffer.from(error.response.data).toString();
			erro = JSON.parse(erro);
			if (!Object.keys(erro).length)
				return { message: "Erro ao realizar exportação!", type: "error"};

			return erro;
		}
	}
}
