<template>
	<b-card
		:border-variant="color"
		:header-bg-variant="color"
		header-text-variant="white"
		align="center"
		class="card-localizacao"
	>
		<template #header>
			<span>{{ maquina }}</span>
			<button class="btn p-0 mr-1 mb-1" @click="$emit('close')" title="Fechar"><x-icon /></button>
		</template>
		<b-card-text class="text-left">
			<span>
				<div><span>Data do Registro: {{ dataRegistro }}</span></div>
				<div><span>Área: {{ area }}</span></div>
				<div><span>Latitude: {{ latitude }}</span></div>
				<div><span>Longitude: {{ longitude }}</span></div>
			</span>
		</b-card-text>
	</b-card>
</template>

<script>
	import dayjs from "dayjs";

	export default {
		props: {
			marker: {
				type: Object,
				required: true
			}
		},

		data () {
			return {
				show: true,
				color: "secondary",
				dataRegistro: dayjs(this.marker.date_module).format("DD/MM/YYYY HH:mm:ss"),
				dataLocalizacao: this.dataRegistro,
				area: this.marker.maquina.area.nome,
				maquina: this.marker.maquina.nome,
				latitude: this.marker.lat,
				longitude: this.marker.lon
			};
		},

		watch: {
			marker () {
				this.dataRegistro = dayjs(this.marker.date_module).format("DD/MM/YYYY HH:mm:ss");
				this.dataLocalizacao = this.dataRegistro;
				this.area = this.marker.maquina.area.nome;
				this.maquina = this.marker.maquina.nome;
				this.latitude = this.marker.lat;
				this.longitude = this.marker.lon;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.card {
		width: fit-content;
		position: absolute;
		bottom: 5px;
	}

	.card-localizacao {
		margin: 0 10px 15px 10px;
	}

	.card-header {
		span {
			line-height: 27px;
		}

		button {
			float: right;

			svg {
				stroke: white;
			}
		}
	}

	.card.tcs-card .card-header {
		padding-top: 10px !important;
		padding-bottom: 5px !important;
		padding-left: 20px !important;
	}

	.card-body span {
		height: 35px;
		line-height: 35px;
		vertical-align: middle;
	}
</style>
